export default {
  computed: {
    citySelection: {
      get() {
        return this.getFilterQueryParam('city');
      },
      set(value) {
        this.setFilterQueryParam('city', value);
      }
    },
    creditorNameSelection: {
      get() {
        return this.getFilterQueryParam('creditorName');
      },
      set(value) {
        this.setFilterQueryParam('creditorName', value);
      }
    },
    emailSelection: {
      get() {
        return this.getFilterQueryParam('email');
      },
      set(value) {
        this.setFilterQueryParam('email', value);
      }
    },
    productSelection: {
      get() {
        return this.getFilterQueryParam('product');
      },
      set(value) {
        this.setFilterQueryParam('product', value);
      }
    },
    sapRegisteredSelection: {
      get() {
        return this.getFilterQueryParam('sapRegistered');
      },
      set(value) {
        this.setFilterQueryParam('sapRegistered', value);
      }
    },
    setUpFeeChargedSelection: {
      get() {
        return this.getFilterQueryParam('setUpFeeCharged');
      },
      set(value) {
        this.setFilterQueryParam('setUpFeeCharged', value);
      }
    },
    userCreatedSelection: {
      get() {
        return this.getFilterQueryParam('userCreated');
      },
      set(value) {
        this.setFilterQueryParam('userCreated', value);
      }
    },
    userNameSelection: {
      get() {
        return this.getFilterQueryParam('userName');
      },
      set(value) {
        this.setFilterQueryParam('userName', value);
      }
    },
    hasActiveFilters() {
      return (
        [].concat(
          this.citySelection,
          this.creditorNameSelection,
          this.emailSelection,
          this.productSelection,
          this.roleSelection,
          this.sapNumberSelection,
          this.sapRegisteredSelection,
          this.setUpFeeChargedSelection,
          this.statusSelection,
          this.userCreatedSelection,
          this.userNameSelection
        ).length > 0
      );
    }
  }
};
